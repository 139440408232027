import axios from "axios";
//f08b05
const onlyplay = axios.create({
  baseURL: process.env.VUE_APP_ONLYPLAY_BASE_URL,
});

onlyplay.defaults.headers.post["Content-Type"] = "application/json";
onlyplay.defaults.method = "post";

onlyplay.interceptors.request.use(
  (config) => {
    const lang = localStorage.getItem("locale") || "en";
    config.headers["lang"] = lang;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default onlyplay;
